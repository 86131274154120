<script>
import { formatApiDate } from "@/utils/utils";
import { getUserAccountList } from "@/api/point";
import { getValue, SHARE_POINT_STATUS } from "@/utils/enumUtile";

export default {
  computed: {
    SHARE_POINT_STATUS() {
      return SHARE_POINT_STATUS;
    },
    formatApiDate() {
      return formatApiDate;
    },
    getValue() {
      return getValue;
    }
  },
  components: {},
  filters: {},
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: ""
      }
    };
  },
  created() {
    this.getList();
  },
  watch: {},
  methods: {
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    /**
     * 查询设备功能列表
     */
    getList() {
      this.listLoading = true;
      const form = this.listQuery;
      const params = {
        ...form
      };
      getUserAccountList(params)
        .then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    }
  }
};
</script>

<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.keyword"
        placeholder="关键字搜索"
        style="width: 250px"
        class="filter-item"
        clearable
      />
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
    </div>

    <div style="margin-bottom: 5px">用户积分总数量:{{ total }}</div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      highlight-current-row
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column
        align="center"
        prop="id"
        label="ID"
        width="95"
      ></el-table-column>
      <el-table-column label="微信昵称 " align="center" prop="wx_name">
        <template slot-scope="scope">
          {{ scope.row.user.wx_name }}
        </template>
      </el-table-column>
      <el-table-column label="姓名 " align="center" prop="name">
        <template slot-scope="scope">
          {{ scope.row.user.name }}
        </template>
      </el-table-column>
      <el-table-column label="电话号码" align="center" prop="mobile">
        <template slot-scope="scope">
          {{ scope.row.user.mobile }}
        </template>
      </el-table-column>
      <el-table-column
        label="爱心积分"
        align="center"
        prop="aixin_amount"
      ></el-table-column>
      <el-table-column
        label="权益积分"
        align="center"
        prop="quanyi_amount"
      ></el-table-column>

      <el-table-column label="状态" width="100" align="center" prop="status">
        <template slot-scope="scope">
          {{ getValue(SHARE_POINT_STATUS, scope.row.status) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="创建时间"
        width="200px"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="修改时间"
        width="200px"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
