<script>
import { formatApiDate, handleClipboard } from "@/utils/utils";
import { withdrawalPayFeedback, getWithdrawalList } from "@/api/point";
import {
  getValue,
  WITHDRAWAL_STATUS,
  WITHDRAWAL_STATUS_FEEDBACK
} from "@/utils/enumUtile";

export default {
  computed: {
    WITHDRAWAL_STATUS() {
      return WITHDRAWAL_STATUS;
    },
    WITHDRAWAL_STATUS_FEEDBACK() {
      return WITHDRAWAL_STATUS_FEEDBACK;
    },
    formatApiDate() {
      return formatApiDate;
    },
    getValue() {
      return getValue;
    }
  },
  components: {},
  filters: {},
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        typeId: "",
        status: ""
      },
      dialogFormVisible: false,
      form: {
        id: "",
        status: "",
        proof: "",
        remark: ""
      },
      rules: {
        status: [{ required: true, message: "状态不能为空", trigger: "change" }]
      },
      btnLoading: false
    };
  },
  created() {
    this.getList();
  },
  watch: {},
  methods: {
    handleClipboard,
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    /**
     * 查询设备功能列表
     */
    getList() {
      this.listLoading = true;
      const form = this.listQuery;
      const params = {
        ...form
      };
      getWithdrawalList(params)
        .then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    /**
     * 切换状态
     * @param status
     * @param editObj
     */
    checkDialogFormVisible(status, editObj = {}) {
      this.dialogFormVisible = status;
      if (editObj && Object.keys(editObj).length !== 0) {
        this.form.id = editObj.id;
        this.form.status = editObj.status;
        this.form.proof = editObj.proof;
        this.form.remark = editObj.remark;
      } else {
        this.form.id = "";
        this.form.status = "";
        this.form.proof = "";
        this.form.remark = [];
        this.$refs.form.resetFields();
      }
    },
    saveData() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const params = this.form;
          this.btnLoading = true;
          withdrawalPayFeedback(params)
            .then(() => {
              this.getList();
              this.checkDialogFormVisible(false, {});
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000
              });
            })
            .finally(() => {
              this.btnLoading = false;
            });
        }
      });
    }
  }
};
</script>

<template>
  <div class="app-container">
    <div class="app-container">
      <div class="filter-container">
        <el-input
          v-model="listQuery.keyword"
          placeholder="关键字搜索"
          style="width: 250px"
          class="filter-item"
          clearable
        />
        <el-select
          v-model="listQuery.status"
          placeholder="状态"
          style="width: 120px"
          class="filter-item"
          clearable
        >
          <el-option
            v-for="item in WITHDRAWAL_STATUS"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          />
        </el-select>
        <el-button
          class="filter-item"
          type="primary"
          icon="el-icon-search"
          @click="handleFilter"
          >搜索</el-button
        >
      </div>

      <div style="margin-bottom: 5px">积分提现总数量:{{ total }}</div>

      <el-table
        v-loading="listLoading"
        :data="list"
        element-loading-text="Loading"
        size="small"
        border
        highlight-current-row
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column
          align="center"
          prop="id"
          label="ID"
          width="95"
        ></el-table-column>
        <el-table-column label="微信昵称 " align="center" prop="wx_name">
          <template slot-scope="scope">
            {{ scope.row.user.wx_name }}
          </template>
        </el-table-column>
        <el-table-column label="姓名 " align="center" prop="name">
          <template slot-scope="scope">
            {{ scope.row.user.name }}
          </template>
        </el-table-column>
        <el-table-column label="电话号码" align="center" prop="mobile">
          <template slot-scope="scope">
            {{ scope.row.user.mobile }}
          </template>
        </el-table-column>

        <el-table-column label="银行名称" align="center" prop="bank_name">
          <template slot-scope="scope">
            {{ scope.row.bank_name
            }}<span
              class="copy-warp"
              @click="handleClipboard($event, scope.row.bank_name)"
              >复制</span
            >
          </template>
        </el-table-column>
        <el-table-column label="开户姓名" align="center" prop="bank_username">
          <template slot-scope="scope">
            {{ scope.row.bank_username
            }}<span
              class="copy-warp"
              @click="handleClipboard($event, scope.row.bank_username)"
              >复制</span
            >
          </template>
        </el-table-column>
        <el-table-column label="银行卡号" align="center" prop="bank_cardno">
          <template slot-scope="scope">
            {{ scope.row.bank_cardno
            }}<span
              class="copy-warp"
              @click="handleClipboard($event, scope.row.bank_cardno)"
              >复制</span
            >
          </template>
        </el-table-column>
        <el-table-column
          label="提现金额"
          align="center"
          prop="amount"
        ></el-table-column>
        <el-table-column label="打款金额" align="center" prop="pay_amount">
          <template slot-scope="scope">
            {{ scope.row.pay_amount
            }}<span
              class="copy-warp"
              @click="handleClipboard($event, scope.row.pay_amount)"
              >复制</span
            >
          </template>
        </el-table-column>
        <el-table-column
          label="手续费"
          align="center"
          prop="fee_amount"
        ></el-table-column>
        <el-table-column label="状态" width="100" align="center" prop="status">
          <template slot-scope="scope">
            {{ getValue(WITHDRAWAL_STATUS, scope.row.status) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="created_at"
          label="创建时间"
          width="200px"
        >
          <template slot-scope="scope">
            <i class="el-icon-time" />
            <span>{{ formatApiDate(scope.row.created_at) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="created_at"
          label="修改时间"
          width="200px"
        >
          <template slot-scope="scope">
            <i class="el-icon-time" />
            <span>{{ formatApiDate(scope.row.updated_at) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          width="160"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="checkDialogFormVisible(true, scope.row)"
              >打款回执</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination
          :current-page="listQuery.page"
          :page-sizes="[10, 20, 30, 50, 100]"
          :page-size="listQuery.limit"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>

    <!--    编辑和新增-->
    <el-dialog
      title="打款回执"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-position="left"
        label-width="150px"
        style="width: 80%; margin-left:50px;"
      >
        <el-form-item label="状态" prop="status">
          <el-select
            v-model="form.status"
            placeholder="请输入"
            style="width: 120px"
            class="filter-item"
            clearable
          >
            <el-option
              v-for="item in WITHDRAWAL_STATUS_FEEDBACK"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="打款凭证" prop="proof">
          <el-input v-model="form.proof" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="form.remark"
            type="textarea"
            height="100"
            :autoHeight="true"
            placeholder="请输入"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkDialogFormVisible(false)">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">
.copy-warp {
  margin-left: 10px;
  color: #1a5fff;
  cursor: pointer;
}
</style>
