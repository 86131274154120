<script>
import { formatApiDate } from "@/utils/utils";
import { getPoints, updatePoints } from "@/api/point";

export default {
  computed: {
    formatApiDate() {
      return formatApiDate;
    }
  },
  components: {},
  filters: {},
  data() {
    return {
      list: null,
      listLoading: true,
      dialogFormVisible: false,
      rules: {
        pvalue: [{ required: true, message: "值不能为空", trigger: "change" }]
      },
      form: {
        pvalue: "",
        id: "",
        remark: ""
      },
      btnLoading: false
    };
  },
  created() {
    this.getList();
  },
  watch: {},
  methods: {
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    /**
     * 查询设备功能列表
     */
    getList() {
      this.listLoading = true;
      getPoints({})
        .then(response => {
          this.list = response.data;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    /**
     * 切换状态
     * @param status
     * @param editObj
     */
    checkDialogFormVisible(status, editObj = {}) {
      this.dialogFormVisible = status;
      if (status) {
        this.form.id = editObj.id;
        this.form.pvalue = editObj.pvalue;
        this.form.remark = editObj.remark;
      } else {
        this.form.id = 0;
        this.form.pvalue = "";
        this.form.remark = "";
      }
    },
    saveData() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const params = this.form;
          delete params.remark;
          this.btnLoading = true;
          updatePoints(params)
            .then(() => {
              this.getList();
              this.checkDialogFormVisible(false, {});
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000
              });
            })
            .finally(() => {
              this.btnLoading = false;
            });
        }
      });
    }
  }
};
</script>

<template>
  <div class="app-container">
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      highlight-current-row
    >
      <el-table-column
        align="center"
        prop="id"
        label="ID"
        width="95"
      ></el-table-column>
      <el-table-column label="键" align="center" prop="pcode"></el-table-column>
      <el-table-column
        label="值"
        align="center"
        prop="pvalue"
      ></el-table-column>
      <el-table-column
        label="描述"
        align="center"
        prop="remark"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="创建时间"
        width="200px"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="修改时间"
        width="200px"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="checkDialogFormVisible(true, scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="编辑"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-position="left"
        label-width="150px"
        style="width: 80%; margin-left:50px;"
      >
        <el-form-item label="值" prop="pvalue">
          <el-input v-model="form.pvalue" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="描述" prop="remark">
          <span>{{ form.remark }}</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkDialogFormVisible(false)">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss"></style>
