<script>
import {
  getValue,
  AD_TYPE,
  AD_MEDIA_TYPE,
  AD_STATUS,
  DIALOG_TITLE_TYPE,
  UPLOAD_IMG_TYPE
} from "@/utils/enumUtile";
import { formatApiDate, getFileName } from "@/utils/utils";
import { getAdList, createAd, updateStatusAd } from "@/api/system";
import { getEquipmentList } from "@/api/equipment";
import UploadList from "@/views/components/uploadList.vue";
import UploadVideo from "@/views/components/uploadVideo.vue";

export default {
  computed: {
    formatApiDate() {
      return formatApiDate;
    },
    AD_TYPE() {
      return AD_TYPE;
    },
    AD_MEDIA_TYPE() {
      return AD_MEDIA_TYPE;
    },
    AD_STATUS() {
      return AD_STATUS;
    },
    DIALOG_TITLE_TYPE() {
      return DIALOG_TITLE_TYPE;
    },
    UPLOAD_IMG_TYPE() {
      return UPLOAD_IMG_TYPE;
    }
  },
  components: { UploadList, UploadVideo },
  filters: {},
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        type: "", // 类型
        mediaType: "", //媒体类型
        status: "", //类型
        typeId: "" // 设备类型id
      },
      form: {
        id: 0,
        type: undefined,
        mediaType: undefined,
        mediaUrl: undefined,
        pageUrl: undefined,
        typeId: undefined,
        attData: [] // 视频数据
      },
      rules: {
        type: [
          { required: true, message: "页面类型不能为空", trigger: "change" }
        ],
        mediaType: [
          { required: true, message: "媒体类型不能为空", trigger: "change" }
        ],
        attData: [
          { required: true, message: "文件不能为空", trigger: "change" }
        ]
      },
      dialogStatus: "CREATE",
      dialogFormVisible: false,
      btnLoading: false,
      typeOption: [] // 类型选项
    };
  },
  created() {
    this.getList();
    this.getTypeOption();
  },
  watch: {},
  methods: {
    getValue,
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },

    /**
     * 查询列表
     */
    getList() {
      this.listLoading = true;
      const form = this.listQuery;
      const params = {
        ...form
      };
      getAdList(params)
        .then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },

    /**
     * 查询设备类型
     */
    getTypeOption() {
      const params = {
        page: 1,
        limit: 9999
      };
      getEquipmentList(params).then(resp => {
        this.typeOption = resp.data.data;
      });
    },

    checkDialogFormVisible(status, obj = {}) {
      this.dialogFormVisible = status;
      if (status) {
        this.form = {
          id: obj.id,
          type: obj.type,
          mediaType: obj.media_type,
          mediaUrl: obj.media_url,
          pageUrl: obj.page_url,
          typeId: obj.type_id
        };
        if (obj.showImgs) {
          const attDataArr = [];
          const arr = obj.showImgs;
          attDataArr.push({
            url: arr.show_url,
            dbUrl: arr.url,
            name: getFileName(arr.show_url)
          });
          this.form.attData = attDataArr;
        }
      } else {
        this.form = {
          id: 0,
          type: undefined,
          mediaType: undefined,
          mediaUrl: undefined,
          pageUrl: undefined,
          typeId: undefined,
          attData: [] // 视频数据
        };
      }
    },

    /**
     * 新增
     */
    saveData() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const form = this.form;

          const params = {
            ...form
          };

          let urlArr = [];
          if (params.attData && params.attData.length !== 0) {
            urlArr = params.attData.map(obj => obj["dbUrl"]);
          }
          params.mediaUrl = urlArr.join(",");

          this.btnLoading = true;
          createAd(params)
            .then(() => {
              this.getList();
              this.checkDialogFormVisible(false, {});
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000
              });
            })
            .finally(() => {
              this.btnLoading = false;
            });
        }
      });
    },
    /**
     * 确认上传音频
     * @param type
     * @param res
     */
    getAttDataValueFunc(type, res) {
      console.log("type",type)
      console.log("res",res)
      if (type === "attData") {
        this.form.attData = res;
      }
    },
    /**
     * 修改广告状态
     * @param scope
     */
    statusChange(scope) {
      const params = {
        id: scope.id,
        status: scope.status === 1 ? 2 : 1
      };
      updateStatusAd(params).then(() => {
        this.getList();
        this.$notify({
          title: "成功",
          message: "状态编辑成功",
          type: "success",
          duration: 2000
        });
      });
    }
  }
};
</script>

<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select
        v-model="listQuery.type"
        placeholder="页面"
        style="width: 200px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in AD_TYPE"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <el-select
        v-model="listQuery.mediaType"
        placeholder="媒体类型"
        style="width: 200px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in AD_MEDIA_TYPE"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <el-select
        v-model="listQuery.status"
        placeholder="状态"
        style="width: 200px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in AD_STATUS"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <el-select
        v-model="listQuery.typeId"
        placeholder="设备类型"
        style="width: 200px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in typeOption"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
      <el-button
        class="filter-item"
        type="primary"
        @click="checkDialogFormVisible(true, {})"
        >新增</el-button
      >
    </div>

    <div style="margin-bottom: 5px">广告图数量：{{ total }}</div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      highlight-current-row
    >
      <el-table-column
        align="center"
        prop="id"
        label="ID"
        width="95"
      ></el-table-column>
      <el-table-column label="页面类型" align="center" prop="evaluate">
        <template slot-scope="scope">
          <span>{{ getValue(AD_TYPE, scope.row.type) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="媒体类型" align="center" prop="media_type">
        <template slot-scope="scope">
          <span>{{ getValue(AD_MEDIA_TYPE, scope.row.media_type) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="媒体" align="center" prop="media_url">
        <template slot-scope="scope">
          <el-image
            :src="scope.row.showImgs.show_url"
            style="width: 100px"
            fit="cover"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="点击跳转URL"
        align="center"
        prop="page_url"
      ></el-table-column>
      <el-table-column align="center" prop="created_at" label="创建时间">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="created_at" label="修改时间">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="180"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            type="warning"
            size="mini"
            @click="checkDialogFormVisible(true, scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            confirm-button-text="好的"
            cancel-button-text="不用了"
            icon="el-icon-info"
            icon-color="red"
            title="确定要修改广告状态吗？"
            @confirm="statusChange(scope.row)"
          >
            <el-switch
              :value="scope.row.status === 1"
              slot="reference"
              style="margin-left: 8px"
            ></el-switch>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!--    编辑和新增-->
    <el-dialog
      :title="DIALOG_TITLE_TYPE[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-position="left"
        label-width="150px"
        style="width: 80%; margin-left:50px;"
      >
        <el-form-item label="页面类型" prop="type">
          <el-select
            v-model="form.type"
            placeholder="请选择页面类型"
            style="width: 100%"
            class="filter-item"
            clearable
          >
            <el-option
              v-for="item in AD_TYPE"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="媒体类型" prop="mediaType">
          <el-select
            v-model="form.mediaType"
            placeholder="请选择媒体类型"
            style="width: 100%"
            class="filter-item"
            clearable
          >
            <el-option
              v-for="item in AD_MEDIA_TYPE"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="[1, 2].includes(form.mediaType)"
          label="媒体路径"
          prop="mediaUrl"
        >
          <UploadList
            :typeProps="UPLOAD_IMG_TYPE.AD"
            paramsType="attData"
            :model="form.attData"
            v-if="form.mediaType === 1"
            @valueFunc="getAttDataValueFunc"
            :maxCount="1"
          />
          <UploadVideo
            v-if="form.mediaType === 2"
            :typeProps="UPLOAD_IMG_TYPE.AD"
            @valueFunc="getAttDataValueFunc"
            paramsType="attData"
            :model="form.attData"
            :maxCount="1"
          />
        </el-form-item>
        <el-form-item label="设备类型" prop="typeId">
          <el-select
            v-model="form.typeId"
            placeholder="请选择媒体类型"
            style="width: 100%"
            class="filter-item"
            clearable
          >
            <el-option
              v-for="item in typeOption"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="页面路径" prop="pageUrl">
          <el-input v-model="form.pageUrl" placeholder="请输入页面路径" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkDialogFormVisible(false)">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss"></style>
