<script>
import { formatApiDate, getFileName } from "@/utils/utils";
import {
  getShareimgList,
  createOrUpdateShareImg,
  updateStatusShareImg
} from "@/api/point";
import {
  POINT_STATUS,
  getValue,
  UPLOAD_IMG_TYPE,
  DIALOG_TITLE_TYPE
} from "@/utils/enumUtile";
import UploadList from "@/views/components/uploadList.vue";

export default {
  computed: {
    POINT_STATUS() {
      return POINT_STATUS;
    },
    DIALOG_TITLE_TYPE() {
      return DIALOG_TITLE_TYPE;
    },
    UPLOAD_IMG_TYPE() {
      return UPLOAD_IMG_TYPE;
    },
    formatApiDate() {
      return formatApiDate;
    },
    getValue() {
      return getValue;
    }
  },
  components: { UploadList },
  filters: {},
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        status: ""
      },
      form: {
        id: "",
        imgPath: [], // 图片
        qrcodeX: "", //二维码X轴起始位置
        qrcodeY: "", //二维码Y轴起始位置
        qrcodeSize: "" //二维码宽高
      },
      rules: {
        qrcodeX: [
          { required: true, message: "二维码X轴起始位置", trigger: "change" }
        ],
        qrcodeY: [
          { required: true, message: "二维码Y轴起始位置", trigger: "change" }
        ],
        qrcodeSize: [
          { required: true, message: "二维码宽高", trigger: "change" }
        ],
        imgs: [{ required: true, message: "图片不能为空", trigger: "change" }]
      },
      dialogStatus: "",
      dialogFormVisible: false,
      btnLoading: false
    };
  },
  created() {
    this.getList();
  },
  watch: {},
  methods: {
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    /**
     * 查询设备功能列表
     */
    getList() {
      this.listLoading = true;
      const form = this.listQuery;
      const params = {
        ...form
      };
      getShareimgList(params)
        .then(response => {
          console.log("response", response);
          this.list = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    /**
     * 切换状态
     * @param status
     * @param editObj
     */
    checkDialogFormVisible(status, editObj = {}) {
      this.dialogFormVisible = status;
      this.dialogStatus =
        editObj && Object.keys(editObj).length !== 0 ? "UPDATE" : "CREATE";
      if (editObj && Object.keys(editObj).length !== 0) {
        this.form.id = editObj.id;
        this.form.qrcodeX = editObj.qrcode_x;
        this.form.qrcodeY = editObj.qrcode_y;
        this.form.qrcodeSize = editObj.qrcode_size;
        if (editObj.showImgs) {
          const attDataArr = [];
          const imgs = editObj.showImgs;
          attDataArr.push({
            url: imgs.show_url,
            dbUrl: imgs.url,
            name: getFileName(imgs.url)
          });
          this.form.imgPath = attDataArr;
        }
      } else {
        this.form.id = "";
        this.form.qrcodeX = "";
        this.form.qrcodeY = "";
        this.form.qrcodeSize = "";
        this.form.imgPath = [];
        this.$refs.form.resetFields();
      }
    },
    /**
     *  获取图片的值
     * @param type
     * @param value
     */
    getImgsValueFunc(type, value) {
      if (type === "imgPath") {
        this.form.imgPath = value;
      }
    },
    saveData() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const form = this.form;
          const params = {
            id: form.id,
            qrcodeX: form.qrcodeX,
            qrcodeY: form.qrcodeY,
            qrcodeSize: form.qrcodeSize
          };
          if (form.imgPath && form.imgPath.length !== 0) {
            const urlArr = form.imgPath.map(obj => obj["dbUrl"]);
            params.imgPath = urlArr.join(",");
          }
          this.btnLoading = true;
          createOrUpdateShareImg(params)
            .then(() => {
              this.getList();
              this.checkDialogFormVisible(false, {});
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000
              });
            })
            .finally(() => {
              this.btnLoading = false;
            });
        }
      });
    },
    /**
     * 切换分享图的状态
     * @param row
     */
    checkSharingImgStatus(row) {
      const params = {
        id: row.id,
        status: row.status === 1 ? 2 : 1
      };
      updateStatusShareImg(params).then(resp => {
        if (resp.code === 0) {
          this.$notify({
            title: "成功",
            message: resp.data,
            type: "success",
            duration: 2000
          });
          this.getList();
        } else {
          this.$notify({
            title: "提示",
            message: resp.data,
            type: "warning",
            duration: 2000
          });
        }
      });
    }
  }
};
</script>

<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select
        v-model="listQuery.status"
        placeholder="状态"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in POINT_STATUS"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
      <el-button
        class="filter-item"
        type="primary"
        @click="checkDialogFormVisible(true, {})"
        >新增</el-button
      >
    </div>

    <div style="margin-bottom: 5px">积分分享背景图数量:{{ total }}</div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      highlight-current-row
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column
        align="center"
        prop="id"
        label="ID"
        width="95"
      ></el-table-column>
      <el-table-column
        label="二维码X轴起始位置"
        align="center"
        prop="qrcode_x"
      ></el-table-column>
      <el-table-column
        label="二维码Y轴起始位置"
        align="center"
        prop="qrcode_y"
      ></el-table-column>
      <el-table-column
        label="二维码宽高"
        align="center"
        prop="qrcode_size"
      ></el-table-column>
      <el-table-column prop="imgs" label="图片" width="120px" align="center">
        <template slot-scope="scope">
          <el-image
            :src="scope.row.showImgs.show_url"
            style="width: 100px"
            fit="cover"
          />
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100" align="center" prop="status">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 1" type="primary">{{ getValue(POINT_STATUS, scope.row.status) }}</el-tag>
          <el-tag v-else-if="scope.row.status === 2" type="warning">{{ getValue(POINT_STATUS, scope.row.status) }}</el-tag>
          <el-tag v-else>后端无字段</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="创建时间"
        width="200px"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="修改时间"
        width="200px"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="330"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="checkDialogFormVisible(true, scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            confirm-button-text="好的"
            cancel-button-text="不用了"
            icon="el-icon-info"
            icon-color="red"
            title="确定修改该分享图的状态吗？"
            @confirm="checkSharingImgStatus(scope.row)"
            style="margin: 0 8px;"
          >
            <el-button slot="reference" type="success" size="mini"
              >切换状态</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!--    编辑和新增-->
    <el-dialog
      :title="DIALOG_TITLE_TYPE[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-position="left"
        label-width="150px"
        style="width: 80%; margin-left:50px;"
      >
        <el-form-item label="图片" prop="imgPath">
          <UploadList
            :typeProps="UPLOAD_IMG_TYPE.COMMON"
            @valueFunc="getImgsValueFunc"
            paramsType="imgPath"
            :model="form.imgPath"
            :maxCount="1"
          />
        </el-form-item>
        <el-form-item label="二维码X轴起始位置" prop="qrcodeX">
          <el-input v-model="form.qrcodeX" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="二维码Y轴起始位置" prop="qrcodeY">
          <el-input v-model="form.qrcodeY" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="二维码宽高" prop="qrcodeSize">
          <el-input v-model="form.qrcodeSize" placeholder="请输入" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkDialogFormVisible(false)">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss"></style>
