<script>
import { formatApiDate } from "@/utils/utils";
import { getShareInfoList } from "@/api/point";
import {
  getValue,
  POINT_TYPE,
  POINT_DISTRIBUTION,
  SHARE_POINT_STATUS
} from "@/utils/enumUtile";

export default {
  computed: {
    POINT_DISTRIBUTION() {
      return POINT_DISTRIBUTION;
    },
    POINT_TYPE() {
      return POINT_TYPE;
    },
    SHARE_POINT_STATUS() {
      return SHARE_POINT_STATUS;
    },
    formatApiDate() {
      return formatApiDate;
    },
    getValue() {
      return getValue;
    }
  },
  components: {},
  filters: {},
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "", // 关键字
        type: "", // 积分类别：1-爱心积分，2-权益积分
        status: "", // 状态：1-正常，2-已到期，3-已领完，4-已取消
        distribution: "" // 分配方式：1-随机，2-平分
      }
    };
  },
  created() {
    this.getList();
  },
  watch: {},
  methods: {
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    /**
     * 查询设备功能列表
     */
    getList() {
      this.listLoading = true;
      const form = this.listQuery;
      const params = {
        ...form
      };
      getShareInfoList(params)
        .then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    }
  }
};
</script>

<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select
        v-model="listQuery.status"
        placeholder="状态"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in SHARE_POINT_STATUS"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <el-select
        v-model="listQuery.distribution"
        placeholder="分配方式"
        style="width: 200px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in POINT_DISTRIBUTION"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <el-select
        v-model="listQuery.type"
        placeholder="积分类别"
        style="width: 200px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in POINT_TYPE"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <el-input
        v-model="listQuery.keyword"
        placeholder="关键字搜索"
        style="width: 250px"
        class="filter-item"
        clearable
      />
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
    </div>

    <div style="margin-bottom: 5px">积分分享记录总数量:{{ total }}</div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      highlight-current-row
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column
        align="center"
        prop="id"
        label="ID"
        width="95"
      ></el-table-column>
      <el-table-column label="微信昵称 " align="center" prop="wx_name">
        <template slot-scope="scope">
          {{ scope.row.user.wx_name }}
        </template>
      </el-table-column>
      <el-table-column label="姓名 " align="center" prop="name">
        <template slot-scope="scope">
          {{ scope.row.user.name }}
        </template>
      </el-table-column>
      <el-table-column label="电话号码" align="center" prop="mobile">
        <template slot-scope="scope">
          {{ scope.row.user.mobile }}
        </template>
      </el-table-column>
      <el-table-column label="爱心大使" align="center" prop="is_aixin">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.user.is_aixin === 1" type="primary"
            >是</el-tag
          >
          <el-tag v-else-if="scope.row.user.is_aixin === 0" type="warning"
            >否</el-tag
          >
          <el-tag v-else>后端无字段</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="微股东" align="center" prop="is_weigudong">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.user.is_weigudong === 1" type="primary"
            >是</el-tag
          >
          <el-tag v-else-if="scope.row.user.is_weigudong === 0" type="warning"
            >否</el-tag
          >
          <el-tag v-else>后端无字段</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="积分类别" width="100" align="center" prop="type">
        <template slot-scope="scope">
          {{ getValue(POINT_TYPE, scope.row.type) }}
        </template>
      </el-table-column>
      <el-table-column
        label="分配方式"
        width="100"
        align="center"
        prop="distribution"
      >
        <template slot-scope="scope">
          {{ getValue(POINT_DISTRIBUTION, scope.row.distribution) }}
        </template>
      </el-table-column>
      <el-table-column
        label="总积分"
        align="center"
        prop="amount"
      ></el-table-column>
      <el-table-column
        label="限制人数"
        align="center"
        prop="dist_peoples"
      ></el-table-column>
      <el-table-column label="状态" width="100" align="center" prop="status">
        <template slot-scope="scope">
          {{ getValue(SHARE_POINT_STATUS, scope.row.status) }}
        </template>
      </el-table-column>
      <el-table-column
        label="到期时间"
        align="center"
        prop="expire_time"
        width="200px"
      >
        <template slot-scope="scope">
          <span>{{ formatApiDate(scope.row.expire_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="创建时间"
        width="200px"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="修改时间"
        width="200px"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
