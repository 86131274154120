<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.orderNo"
        placeholder="系统支付单号"
        style="width: 140px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.typeId"
        placeholder="设备类别"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in equipmentOption"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
      <el-select
        v-model="listQuery.storeId"
        placeholder="门店"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in storeOption"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
      <el-input
        v-model="listQuery.devicesNum"
        placeholder="设备编号"
        style="width: 140px"
        class="filter-item"
        clearable
      />
      <el-input
        v-model="listQuery.userkw"
        placeholder="用户关键字(手机号码，姓名，微信昵称)"
        style="width: 250px"
        class="filter-item"
        clearable
      />
      <CascadeRegion
        :isRequired="true"
        :value="listQuery.cascadeRegion"
        @valueFunc="getQueryValueFunc"
        type="cascadeRegion"
        :styleRef="{ width: '210px', marginRight: '6px', top: '-4px' }"
      />
      <el-select
        v-model="listQuery.status"
        placeholder="状态"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in SERVICE_BILL_STATUS"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
    </div>

    <div style="margin-bottom: 5px">服务单数量：{{ total }}</div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      highlight-current-row
    >
      <el-table-column
        align="center"
        prop="id"
        label="ID"
        width="95"
      ></el-table-column>
      <el-table-column
        label="服务单号"
        align="center"
        prop="order_num"
      ></el-table-column>
      <el-table-column align="center" label="门店">
        <template slot-scope="scope">
          <span>{{ scope.row.store.name }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="设备类别">
        <template slot-scope="scope">
          <span>{{ scope.row.device_type.name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="省/市/区/街道"
        width="220"
        align="center"
        prop="name"
      >
        <template slot-scope="scope">
          {{ scope.row.province ? scope.row.province.name : "无" }} /
          {{ scope.row.city ? scope.row.city.name : "无" }} /
          {{ scope.row.area ? scope.row.area.name : "无" }} /
          {{ scope.row.street ? scope.row.street.name : "无" }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="amount" label="金额">
      </el-table-column>
      <el-table-column align="center" prop="duration" label="购买时长">
      </el-table-column>
      <el-table-column align="center" label="状态">
        <template slot-scope="scope">
          <span>{{ getValue(SERVICE_BILL_STATUS, scope.row.status) }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="created_at" label="支付时间">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.pay_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="created_at" label="创建时间">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatDateTime(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="created_at" label="修改时间">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatDateTime(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
<script>
import { formatApiDate, formatDateTime } from "@/utils/utils";
import { getValue, SERVICE_BILL_STATUS } from "@/utils/enumUtile";
import { getDevicesOrderList } from "@/api/store";
import { getStoreList } from "@/api/store";
import { getEquipmentList } from "@/api/equipment";
import CascadeRegion from "@/views/components/cascadeRegion.vue";

export default {
  computed: {
    SERVICE_BILL_STATUS() {
      return SERVICE_BILL_STATUS;
    },
    formatApiDate() {
      return formatApiDate;
    },
    formatDateTime() {
      return formatDateTime;
    }
  },
  components: { CascadeRegion },
  filters: {},
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        typeId: "", // 设备类别ID
        storeId: "", //门店ID
        devicesNum: "", //设备编号
        userkw: "", //用户关键字(手机号码，姓名，微信昵称)
        status: "", //状态:1-待支付，2-支付处理中，3-已支付，4-服务中，5-已结束，6-已作废
        cascadeRegion: [],
        provinceId: "",
        cityId: "",
        areaId: "",
        streetId: ""
      },
      storeOption: [],
      equipmentOption: []
    };
  },
  created() {
    this.getList();
    this.getStoreList();
    this.getEquipmentList();
  },
  watch: {},
  methods: {
    getValue,
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    /**
     * 查询设备功能列表
     */
    getList() {
      this.listLoading = true;
      const form = this.listQuery;
      const params = {
        ...form
      };
      getDevicesOrderList(params)
        .then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    /**
     * 查询门店
     */
    getStoreList() {
      const params = {
        page: 1,
        limit: 9999
      };
      getStoreList(params).then(resp => {
        this.storeOption = resp.data.data;
      });
    },
    /**
     * 查询设备类别
     */
    getEquipmentList() {
      const params = {
        page: 1,
        limit: 9999
      };
      getEquipmentList(params).then(resp => {
        this.equipmentOption = resp.data.data;
      });
    },
    /**
     * 省市区街道的值获取
     * @param type
     * @param resultArr
     */
    getQueryValueFunc(type, resultArr) {
      if (type === "cascadeRegion") {
        this.listQuery.cascadeRegion = resultArr;
        if (resultArr && resultArr.length !== 0) {
          this.listQuery.provinceId = resultArr[0];
          this.listQuery.cityId = resultArr[1];
          this.listQuery.areaId = resultArr[2];
          this.listQuery.streetId = resultArr[3];
        } else {
          this.listQuery.provinceId = "";
          this.listQuery.cityId = "";
          this.listQuery.areaId = "";
          this.listQuery.streetId = "";
        }
      }
    }
  }
};
</script>
<style scoped lang="scss"></style>
