import request from "@/utils/request";

/**
 * 添加 or 编辑 银行
 * @param params
 * @returns {*}
 */
export function createOrUpdateBank(params) {
  return request({
    url: "/api/backend/bank/setting/create",
    method: "post",
    data: params
  });
}

/**
 * 查询银行
 * @param params
 * @returns {*}
 */
export function getBankList(params) {
  return request({
    url: "/api/backend/bank/setting/getList",
    method: "get",
    params
  });
}
/**
 * 查询用户银行
 * @param params
 * @returns {*}
 */
export function getUserBankcards(params) {
  return request({
    url: "/api/backend/bank/bankcard/getUserBankcards",
    method: "get",
    params
  });
}
