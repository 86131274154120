<script>
import { formatApiDate, getFileName } from "@/utils/utils";
import {
  DIALOG_TITLE_TYPE,
  UPLOAD_IMG_TYPE,
  POINT_STATUS,
  getValue
} from "@/utils/enumUtile";
import { getBankList, createOrUpdateBank } from "@/api/bank";
import UploadList from "@/views/components/uploadList.vue";

export default {
  computed: {
    DIALOG_TITLE_TYPE() {
      return DIALOG_TITLE_TYPE;
    },
    UPLOAD_IMG_TYPE() {
      return UPLOAD_IMG_TYPE;
    },
    POINT_STATUS() {
      return POINT_STATUS;
    },
    formatApiDate() {
      return formatApiDate;
    },
    getValue() {
      return getValue;
    }
  },
  components: { UploadList },
  filters: {},
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        typeId: ""
      },
      dialogFormVisible: false,
      editObj: {},
      dialogStatus: "CREATE",
      form: {
        id: "",
        bankName: "",
        bankLogo: []
      },
      rules: {
        bankLogo: [
          { required: true, message: "银行logo不能为空", trigger: "blur" }
        ],
        bankName: [{ required: true, message: "银行名称", trigger: "blur" }]
      },
      btnLoading: false
    };
  },
  created() {
    this.getList();
  },
  watch: {},
  methods: {
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    /**
     * 查询设备功能列表
     */
    getList() {
      this.listLoading = true;
      const form = this.listQuery;
      const params = {
        ...form
      };
      getBankList(params)
        .then(response => {
          console.log("response", response);
          this.list = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },

    /**
     * 切换状态
     * @param status
     * @param editObj
     */
    checkDialogFormVisible(status, editObj = {}) {
      this.dialogFormVisible = status;
      this.editObj = editObj;
      this.dialogStatus =
        editObj && Object.keys(editObj).length !== 0 ? "UPDATE" : "CREATE";
      if (status && editObj && Object.keys(editObj).length !== 0) {
        this.form.id = editObj.id;
        this.form.bankName = editObj.bank_name;
        if (editObj.showImgs) {
          const attDataArr = [];
          const imgs = editObj.showImgs;
          attDataArr.push({
            url: imgs.show_url,
            dbUrl: imgs.url,
            name: getFileName(imgs.url)
          });
          this.form.bankLogo = attDataArr;
        }
      } else {
        this.form.id = "";
        this.form.bankName = "";
        this.form.bankLogo = [];
        this.$refs.form.resetFields();
      }
    },
    /**
     * 确认添加还是编辑
     */
    saveData() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const form = this.form;
          const params = {
            id: form.id,
            bankName: form.bankName
          };
          if (form.bankLogo && form.bankLogo.length !== 0) {
            const urlArr = form.bankLogo.map(obj => obj["dbUrl"]);
            params.bankLogo = urlArr.join(",");
          }
          this.btnLoading = true;
          createOrUpdateBank(params)
            .then(() => {
              this.getList();
              this.checkDialogFormVisible(false, {});
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000
              });
            })
            .finally(() => {
              this.btnLoading = false;
            });
        }
      });
    },
    /**
     *  获取图片的值
     * @param type
     * @param value
     */
    getImgsValueFunc(type, value) {
      if (type === "bankLogo") {
        this.form.bankLogo = value;
      }
    }
  }
};
</script>

<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button
        class="filter-item"
        type="primary"
        @click="checkDialogFormVisible(true, {})"
        >新增</el-button
      >
    </div>

    <div style="margin-bottom: 5px">银行数量:{{ total }}</div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      highlight-current-row
    >
      <el-table-column
        align="center"
        prop="id"
        label="ID"
        width="95"
      ></el-table-column>
      <el-table-column
        label="银行名称"
        align="center"
        prop="bank_name"
      ></el-table-column>
      <el-table-column prop="bank_logo" label="银行logo" align="center">
        <template slot-scope="scope">
          <el-image
            :src="scope.row.showImgs.show_url"
            style="width: 100px"
            fit="cover"
          />
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center" prop="status">
        <template slot-scope="scope">
          {{ getValue(POINT_STATUS, scope.row.status) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="创建时间"
        width="200px"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="修改时间"
        width="200px"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="checkDialogFormVisible(true, scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!--    编辑和新增-->
    <el-dialog
      :title="DIALOG_TITLE_TYPE[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-position="left"
        label-width="150px"
        style="width: 80%; margin-left:50px;"
      >
        <el-form-item label="银行logo" prop="bankLogo">
          <UploadList
            :typeProps="UPLOAD_IMG_TYPE.COMMON"
            @valueFunc="getImgsValueFunc"
            paramsType="bankLogo"
            :model="form.bankLogo"
            :maxCount="1"
          />
        </el-form-item>
        <el-form-item label="银行名称" prop="bankName">
          <el-input v-model="form.bankName" placeholder="请输入" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkDialogFormVisible(false)">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss"></style>
