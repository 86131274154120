import request from "@/utils/request";

/**
 * 创建门店
 * @param params
 * @returns {*}
 */
export function createStore(params) {
  return request({
    url: "/api/backend/store/create",
    method: "post",
    data: params
  });
}

/**
 * 查询门店信息
 * @param params
 * @returns {*}
 */
export function getStoreList(params) {
  return request({
    url: "/api/backend/store/getList",
    method: "get",
    params
  });
}

/**
 * 查询服务单列表
 * @param params
 * @returns {*}
 */
export function getDevicesOrderList(params) {
  return request({
    url: "/api/backend/store/getDevicesOrderList",
    method: "get",
    params
  });
}

/**
 * 查询标签列表
 * @param params
 * @returns {*}
 */
export function getTagList(params) {
  return request({
    url: "/api/backend/store/evaluate/getTagList",
    method: "get",
    params
  });
}

/**
 * 创建标签
 * @param params
 * @returns {*}
 */
export function createTag(params) {
  return request({
    url: "/api/backend/store/evaluate/createTag",
    method: "post",
    data: params
  });
}

/**
 * 删除标签
 * @param params
 * @returns {*}
 */
export function deleteTag(params) {
  return request({
    url: "/api/backend/store/evaluate/deleteTag",
    method: "post",
    data: params
  });
}

/**
 * 获取充值二维码
 * @param params
 * @returns {*}
 */
export function getRechargeQrcode(params) {
  return request({
    url: "/api/backend/store/getRechargeQrcode",
    method: "get",
    params
  });
}
