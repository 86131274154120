import request from "@/utils/request";

/**
 * 修改设置
 * @param params
 * @returns {*}
 */
export function updatePoints(params) {
  return request({
    url: "/api/backend/points/setting/update",
    method: "post",
    data: params
  });
}

/**
 * 查询设置
 * @param params
 * @returns {*}
 */
export function getPoints(params) {
  return request({
    url: "/api/backend/points/setting/get",
    method: "get",
    params
  });
}

//==================================================

/**
 * 查询积分分享图列表
 * @param params
 * @returns {*}
 */
export function getShareimgList(params) {
  return request({
    url: "/api/backend/points/shareimg/getList",
    method: "get",
    params
  });
}

/**
 * 修改/创建分享图片背景
 * @param params
 * @returns {*}
 */
export function createOrUpdateShareImg(params) {
  return request({
    url: "/api/backend/points/shareimg/create",
    method: "post",
    data: params
  });
}
/**
 * 修改分享图片背景状态
 * @param params
 * @returns {*}
 */
export function updateStatusShareImg(params) {
  return request({
    url: "/api/backend/points/shareimg/updateStatus",
    method: "post",
    data: params
  });
}

/**
 * 查询积分分享记录列表
 * @param params
 * @returns {*}
 */
export function getShareInfoList(params) {
  return request({
    url: "/api/backend/points/share/getList",
    method: "get",
    params
  });
}
/**
 * 查询用户积分
 * @param params
 * @returns {*}
 */
export function getUserAccountList(params) {
  return request({
    url: "/api/backend/points/userAccount/getList",
    method: "get",
    params
  });
}

/**
 * 查询提现记录
 * @param params
 * @returns {*}
 */
export function getWithdrawalList(params) {
  return request({
    url: "/api/backend/points/withdrawal/getList",
    method: "get",
    params
  });
}
/**
 * 打款反馈
 * @param params
 * @returns {*}
 */
export function withdrawalPayFeedback(params) {
  return request({
    url: "/api/backend/points/withdrawal/payFeedback",
    method: "post",
    data: params
  });
}
